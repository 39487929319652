@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'chatthai';
  src: url('fonts/chatthai/CSChatThaiUI-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: light;
}

@font-face {
  font-family: 'chatthai';
  src: url('fonts/chatthai/CSChatThaiUI-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'chatthai';
  src: url('fonts/chatthai/CSChatThaiUI-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: 'sukhumvit';
  src: url('fonts/sukhumvit/SukhumvitSet-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: light;
}

@font-face {
  font-family: 'sukhumvit';
  src: url('fonts/sukhumvit/SukhumvitSet-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'sukhumvit';
  src: url('fonts/sukhumvit/SukhumvitSet-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: medium;
}

@font-face {
  font-family: 'sukhumvit';
  src: url('fonts/sukhumvit/SukhumvitSet-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: semi-bold;
}

@font-face {
  font-family: 'sukhumvit';
  src: url('fonts/sukhumvit/SukhumvitSet-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: bold;
}

.navBg {
  -webkit-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
  opacity: 1;
  background: #ffffffe3;
}

.swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  height: calc((100% - 30px) / 2) !important;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: rgb(35 154 155 / var(--tw-bg-opacity)) !important;
  --tw-bg-opacity: 1 !important;
  border-radius: 0.25rem !important;
  width: 1rem;
  height: 0.5rem;
}

.swiper {
  padding-bottom: 30px !important;
}

#partner .swiper-slide {
  border-radius: 16px;
  background-color: white;
  /* box-shadow: 4px 4px 4px #5CCBC445; */
  --tw-drop-shadow: drop-shadow(4px 4px 4px #5ccbc445);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale)
    var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.swiper-pagination-bullet {
  background-color: rgb(131 131 131 / var(--tw-bg-opacity)) !important;
  --tw-bg-opacity: 1 !important;
  border-radius: 0.25rem !important;
  width: 0.813rem;
  height: 0.375rem;
}

.swiper-pagination-bullet-active {
  color: #fff;
  background: #007aff;
}

.serviceSwiper .swiper-button-next,
.serviceSwiper .swiper-button-prev {
  display: none !important;
}

.serviceSwiper .swiper-slide {
  background: transparent;
}

@layer base {
  .text-balance {
    text-wrap: balance;
  }
  html {
    font-weight: 400;
    line-height: 26px;
    font-size: 16px;
    font-family: 'chatthai';

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.modalOpenUp {
  overflow: hidden;
}

h1 {
  font-size: 2rem;
  font-weight: bolder;
  color: black;
  line-height: 60px;
}

h3 {
  font-size: 1.17rem;
  font-weight: bolder;
  color: black;
}

h1 > strong {
  color: black;
}

h3 > strong {
  color: #575757;
}

li > strong {
  color: #575757;
}

.ql-align-center,
h1 {
  display: flex;
  justify-content: center;
}

ul {
  list-style: inside;
}

ol {
  list-style: auto;
  margin-left: 20px;
}

div.text-neutral-gray-base.pr-6.text-chatthai16.font-chatthai.font-chatthaiw400.pt-2 {
  white-space: pre-line;
}
